import axios from "axios";
import CryptoJS from "crypto-js";

import config from "./../config/app.config";

let LIVE_CTX = config.REST_API_CTX;

const LIVE_REST_API_PREFIX =
  LIVE_CTX.PROTOCOL + LIVE_CTX.HOST + LIVE_CTX.PORT + LIVE_CTX.API;

const LIVE_REST_API_EXT_PREFIX = LIVE_REST_API_PREFIX + LIVE_CTX.EXT;

const LIVE_REST_API_PREFIX_LOOKUP = LIVE_REST_API_EXT_PREFIX + LIVE_CTX.LOOKUP;

const SECRET_KEY = 'certif-ui';

const LIVE_REST_API = {
  USER_INFO: LIVE_REST_API_PREFIX + "/users/userinfo/",
  VALIDATE_MEMBERSHIP_NUMBER: LIVE_REST_API_EXT_PREFIX + "/validateprogram/",
  GET_LANGUAGES: LIVE_REST_API_EXT_PREFIX + "/certificatelanguage/",
  GET_COUNTRIES: LIVE_REST_API_EXT_PREFIX + "/countries/",
  MESSAGE_CODES: LIVE_REST_API_EXT_PREFIX + "/messages/",
  GET_TITLES: LIVE_REST_API_PREFIX_LOOKUP + "/title/",
  GET_PROFILE: LIVE_REST_API_PREFIX_LOOKUP + "/profile/",
  SUBMIT_FINAL_OBJ: LIVE_REST_API_EXT_PREFIX + "/certifrequest/",
  EMAIL_TEMPLATES: LIVE_REST_API_PREFIX + "/legalentity/emailtemplates/",
  IDENTIFIER_LABELS: LIVE_REST_API_PREFIX + "/locale/",
  LOOKUP_LABELS: LIVE_REST_API_PREFIX + "/lookup/",
  COUNTRY_LABELS: LIVE_REST_API_PREFIX + "/country/",
  LANGUAGES: LIVE_REST_API_PREFIX + "/languages/",
  REGIONS: LIVE_REST_API_PREFIX + "/country/regions/",
  COUNTRY_DETAILS: LIVE_REST_API_PREFIX + "/country/details/",
  LEGAL_ENTITY: LIVE_REST_API_PREFIX + "/legalentity/",
  CURRENCY_LABELS: LIVE_REST_API_PREFIX + "/currency/",
  COURIER_SERVICE_PROVIDER: LIVE_REST_API_PREFIX + "/courierserviceprovider/",
  MESSAGE: LIVE_REST_API_PREFIX + "/messages/",
  MESSAGE_DETAILS: LIVE_REST_API_PREFIX + "/messages/details/",
  SELLING_OFFICE: LIVE_REST_API_PREFIX + "/sellingoffice/dropdownlist",
  LEGAL_ENTITY_DD: LIVE_REST_API_PREFIX + "/legalentity/dropdownlist",
  ROLE: LIVE_REST_API_EXT_PREFIX + "/lookup/requestor/en",
  DESTINATION_COUNTRY: LIVE_REST_API_PREFIX + "/country/dropdownlist",
  USER: LIVE_REST_API_PREFIX + "/users/dropdownlist",
  EXPORT_TO_EXCEL: LIVE_REST_API_PREFIX + "/report/search",
  PROGRAM_DETAILS: LIVE_REST_API_PREFIX + "/program/details/",
  CERTIFICATE_TEMPLATE: LIVE_REST_API_PREFIX + "/certificatetemplate/",
  USER_MANAGEMENT: LIVE_REST_API_PREFIX + "/users/",
  SELLING_OFFICE_MANAGEMENT: LIVE_REST_API_PREFIX + "/sellingoffice/",
  SELLING_OFFICE_SYN_DATA: LIVE_REST_API_PREFIX + "/sellingoffice/sync/",
  SELLING_OFFICE_COUNTRY_LIMIT:
    LIVE_REST_API_PREFIX + "/sellingoffice/countrylimit/",
  SELLING_OFFICE_MARKETING_CONTENT:
    LIVE_REST_API_PREFIX + "/sellingoffice/marketingcontent/",
  CERTIFICATE_LINK_CONFIG: LIVE_REST_API_PREFIX + "/certiflinkconfig/",
};

const APP_URL_PREFIX = "";

const ADMIN_PAGE = APP_URL_PREFIX + "/admin";

let APP_URLS = {
  ADMIN_PAGE,
  LANDING_PAGE: APP_URL_PREFIX + "/",
  TERMS_PAGE: APP_URL_PREFIX + "/termsandconditions",
  LOGIN_PAGE: APP_URL_PREFIX + "/internal",
  LOGOUT_REDIRECT_PAGE: APP_URL_PREFIX + "/logout",
  LOGIN_CALLBACK_PAGE: APP_URL_PREFIX + "/internal/callback",
  REPORTS_PAGE: APP_URL_PREFIX + "/reports",
  PROGRAM_DETAILS_PAGE: APP_URL_PREFIX + "/programdetails",
  ERROR_PAGE: APP_URL_PREFIX + "/errorpage",
};

APP_URLS.ADMIN_PAGE_SECTION_1 = ADMIN_PAGE + "/legalentity";
APP_URLS.ADMIN_PAGE_SECTION_2 = ADMIN_PAGE + "/labels";
APP_URLS.ADMIN_PAGE_SECTION_3 = ADMIN_PAGE + "/lookup";
APP_URLS.ADMIN_PAGE_SECTION_4 = ADMIN_PAGE + "/country";
APP_URLS.ADMIN_PAGE_SECTION_5 = ADMIN_PAGE + "/masters";
APP_URLS.ADMIN_PAGE_SECTION_6 = ADMIN_PAGE + "/messages";
APP_URLS.ADMIN_PAGE_SECTION_7 = ADMIN_PAGE + "/certificate";
APP_URLS.ADMIN_PAGE_SECTION_8 = ADMIN_PAGE + "/user";
APP_URLS.ADMIN_PAGE_SECTION_9 = ADMIN_PAGE + "/audit";
APP_URLS.ADMIN_PAGE_SECTION_10 = ADMIN_PAGE + "/sellingoffice";
APP_URLS.ADMIN_PAGE_SECTION_11 = ADMIN_PAGE + "/marketingcontentmanagement";
APP_URLS.ADMIN_PAGE_SECTION_12 = ADMIN_PAGE + "/certiflinkconfig";

APP_URLS.LEGAL_ENTITY = APP_URLS.ADMIN_PAGE_SECTION_1 + "/legalentity";
APP_URLS.EMAIL_TEMPLATE = APP_URLS.ADMIN_PAGE_SECTION_1 + "/emailtemplate";
APP_URLS.MARKETING_CONTENT =
  APP_URLS.ADMIN_PAGE_SECTION_1 + "/marketingcontent";

APP_URLS.LABELS_TEMPLATE = APP_URLS.ADMIN_PAGE_SECTION_2 + "/labeltemplate";
APP_URLS.LABELS_CONTENT = APP_URLS.ADMIN_PAGE_SECTION_2 + "/labelcontent";
APP_URLS.LABELS_MANAGEMENT = APP_URLS.ADMIN_PAGE_SECTION_2 + "/labelmanagement";

APP_URLS.LOOKUP_MANAGEMENT =
  APP_URLS.ADMIN_PAGE_SECTION_3 + "/lookupmanagement";

APP_URLS.COUNTRY_MANAGEMENT =
  APP_URLS.ADMIN_PAGE_SECTION_4 + "/countrymanagement";

APP_URLS.CURRENCY_MANAGEMENT =
  APP_URLS.ADMIN_PAGE_SECTION_5 + "/currencymanagement";
APP_URLS.COURIER_SERVICE_PROVIDER =
  APP_URLS.ADMIN_PAGE_SECTION_5 + "/courierserviceprovider";

APP_URLS.MESSAGE = APP_URLS.ADMIN_PAGE_SECTION_6 + "/messages";

APP_URLS.CERTIFICATE_TEMPLATE =
  APP_URLS.ADMIN_PAGE_SECTION_7 + "/certificatetemplate";

APP_URLS.USER_MANAGEMENT = APP_URLS.ADMIN_PAGE_SECTION_8 + "/usermanagement";

APP_URLS.AUDIT_INFORMATION =
  APP_URLS.ADMIN_PAGE_SECTION_9 + "/auditinformation";

APP_URLS.SELLING_OFFICE_MANAGEMENT =
  APP_URLS.ADMIN_PAGE_SECTION_10 + "/sellingoffice";

APP_URLS.MARKETING_CONTENT_MANAGEMENT =
  APP_URLS.ADMIN_PAGE_SECTION_11 + "/marketingcontentmanagement";

APP_URLS.CERTIFICATE_LINK_CONFIG =
  APP_URLS.ADMIN_PAGE_SECTION_12 + "/certiflinkconfig";

const NAV_ITEMS_KEYS = ["home"];
const ADMIN_NAV_ITEMS_KEYS = ["admin", "reports", "programdetails"];
const USER_NAV_ITEMS_KEYS = ["reports", "programdetails"];

const NAV_ITEMS_VALUES = {
  home: {
    title: "home",
    url: APP_URLS.LANDING_PAGE,
    enabled: true,
    handler: (setPageInfo) => {
      resetSession([
        "general_information",
        "requests",
        "externalEmails",
        "edit",
      ]);
      setStoreObj(0, setPageInfo);
      return true;
    },
  },
  admin: {
    title: "Admin",
    url: APP_URLS.ADMIN_PAGE,
    handler: (setPageInfo) => {
      setStoreObj(6, setPageInfo);
      return true;
    },
  },
  reports: {
    title: "Reports",
    url: APP_URLS.REPORTS_PAGE,
    handler: (setPageInfo) => {
      setStoreObj(7, setPageInfo);
      return true;
    },
  },
  programdetails: {
    title: "ProgramDetails",
    url: APP_URLS.PROGRAM_DETAILS_PAGE,
    handler: (setPageInfo) => {
      setStoreObj(11, setPageInfo);
      return true;
    },
  },
};

// DANGER: DO NOT CHANGE THE ORDER/INDEX
const PAGES = [
  "home",
  "general_information",
  "certificate_information",
  "confirmation",
  "validation",
  "terms_and_conditions",
  "admin",
  "reports",
  "callback",
  "",
  "internal",
  "programdetails",
];

const EMPTY_ERROR_FIELDS = {
  empFirstName: "17",
  empLastName: "18",
  empEmail: "19",
  language: "30",
  destinationCountry: "29",
  startDate: "25",
  endDate: "26",
  bnfFirstName: "20",
  bnfLastName: "21",
  bnfEmail: "19",
  bnfRelation: "32",
  sdFirstName: "22",
  sdLastName: "23",
  sdPhone: "24",
  subsidiary: "37",
  genInfoDropdown: "16",
  understandRisk: "48",
  "certif-regions-wrapper": "44",
  programDetailsPage: "11",
  adminSectionsMandatoryFields: "11",
  currency: "49",
  // FieldId: "messageCode"
};

const NON_EMPTY_ERROR_FIELDS = {
  empEmail: "15",
  bnfEmail: "15",
  sdPhone: "35",
  sdZipCode: "38",
  maxCertificates: "13",
  endDate: "14",
  expiryDate: "34",
  certificatePeriod: "42",

  // FieldId: "messageCode"
};

const ERROR_TYPE_TO_FIELDS_MAPPING = {
  empty: "EMPTY_ERROR_FIELDS",
  invalidFormat: "NON_EMPTY_ERROR_FIELDS",
  // ... add more mappings as needed
};

const MAX_RECORDS_ALLOWED = 10;
const MEDICAL_EXPENSES_LIMIT = 300000.00;
const FURTHER_HELP_OR_SUPPORT_LINK = "https://clientsupport.internationalsos.com/s/contactsupport"

const CURRENCIES = {
  "en": [
    { value: '', label: 'Select' },
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' }
  ],
  "fr": [
    { value: '', label: 'sélectionner' },
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' }
  ]
};

const ADMIN_SECTIONS_ERROR_TEMPLATE = "{} should not be empty";

const PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS = "Click here to Search";

const USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS = {
  save: "Your data record is saved successfully",
  saveAlt: "Something went wrong during save",
  update: "Your data record is updated successfully",
  updateAlt: "Something went wrong during update",
  delete: "Your data record is deleted successfully",
  deleteAlt: "Something went wrong during delete",
  errorAlt: "An error occurred while processing your request.",
};

const LOCAL_STORAGE = window.localStorage;
const STORAGE = LOCAL_STORAGE;

const SESSION = "certif_session_app_ctx";
const AUTH_OBJ = "authenticaion";
// const TOKEN = "accessToken";
const TOKEN = "access_token";
const GUEST_ACCESS = "guest_access";
const USER_INFO_OBJ = "userInfo";
const CERTIF_ROLES = "certifRoles";
const SUPER_ADMIN_ROLE_NAME = "ROLE_SUPER_ADMIN";

const BASIC_SESSION_OBJ = {
  locale: "en",
  path: PAGES[0],
};

const SET_ITEM_IN_STORAGE = (data) => {
  data = JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
  STORAGE.setItem(SESSION, encryptedData);
}

const GET_ITEM_FROM_STORAGE = () => {
  const encrypted = STORAGE.getItem(SESSION) ?? "{}";
  let decrypted = "";
  try {
    decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error(error);
    // TODO: check this later to avoid decrypt error.
    window.location.reload();
    decrypted = "";
  }
  if (decrypted === "") {
    decrypted = "{}";
  }
  return JSON.parse(decrypted);
}

const createSession = (obj = {}) => {
  deleteSession();

  let storageObj = BASIC_SESSION_OBJ;

  const storageValue = {
    value: storageObj,
  };
  //STORAGE.setItem(SESSION, JSON.stringify(storageValue));
  SET_ITEM_IN_STORAGE(storageValue);
};

const updateSession = (obj = {}, deletableAttrs = []) => {
  let storageObj = getSession();
  storageObj = { ...storageObj, ...obj };

  deletableAttrs.forEach((del) => {
    delete storageObj[del];
  });

  const storageValue = {
    value: storageObj,
  };

  // STORAGE.setItem(SESSION, JSON.stringify(storageValue));
  SET_ITEM_IN_STORAGE(storageValue);
};

const getSession = () => {
  let itemStr = GET_ITEM_FROM_STORAGE();
  if (!itemStr) {
    createSession();
    itemStr = GET_ITEM_FROM_STORAGE();
  }
  const item = itemStr;
  return item.value ?? "";
};

const isObjectEmpty = (obj = {}) => {
  return Object.keys(obj).length === 0;
};

const setSession = (storageObj = {}) => {
  const storageValue = {
    value: storageObj,
  };

  // console.log(storageValue);

  // STORAGE.setItem(SESSION, JSON.stringify(storageValue));
  SET_ITEM_IN_STORAGE(storageValue);
};

const createOKTASession = (userObj) => {
  // console.log(userObj);
  let storageObj = { ...getSession() };
  storageObj[AUTH_OBJ] = { ...userObj };
  delete storageObj[AUTH_OBJ]?.GUEST_ACCESS;
  storageObj["reload"] = true;

  setSession(storageObj);
};

const createGuestSession = (userObj = {}) => {
  let storageObj = { ...getSession() };
  storageObj[AUTH_OBJ] = { ...userObj };
  storageObj["reload"] = true;

  setSession(storageObj);
};

const deleteSession = (info) => {
  STORAGE.removeItem(SESSION);
};

const resetSession = (removableProperties = []) => {
  let currentStorage = { ...getSession() } ?? {};

  console.log(currentStorage);
  removableProperties.length > 0 &&
    removableProperties.forEach((key) => {
      delete currentStorage[key];
    });
  // console.log(currentStorage);
  setSession(currentStorage);
};

const getToken = (session = getSession()) => {
  let token = (session[AUTH_OBJ] ?? {})[TOKEN];
  // console.log(token);
  return token?.length ? "Bearer " + token : "";
};

const isGuestAccess = (session = getSession()) => {
  let isGuest = (session[AUTH_OBJ] ?? {})[GUEST_ACCESS] ?? false;
  // console.log(token);
  return isGuest;
};

const isSuperAdminAccess = (session = getSession()) => {
  let isSuperAdmin = ((session[USER_INFO_OBJ] ?? {})[CERTIF_ROLES] ?? []).includes(SUPER_ADMIN_ROLE_NAME);
  console.log(isSuperAdmin);
  return isSuperAdmin;
}

const getLanguages = (loc) => {
  const myPromise = new Promise((resolve, reject) => {
    let getUrl = LIVE_REST_API.GET_LANGUAGES + loc;
    axios
      .get(
        getUrl,
        { validateStatus: false },
        {
          headers: {},
        },
      )
      .then((res) => {
        // console.log(res);
        resolve(res);
      })
      .catch(function (error) {
        // console.log(error.response.status);
        // console.log(error.response.data.error);
        //if (error.response.status === 401) {
        reject({
          redirect: true,
          error,
        });
        //}
      });
  });

  return myPromise;
};

const getStoredLanguages = () => {
  const myPromise = new Promise((resolve, reject) => {
    let getUrl = LIVE_REST_API.LANGUAGES;
    let session = getSession();
    if (session.languages) {
      resolve(session.languages);
    } else {
      let token = getToken();
      if (token) {
        let headersObj = {
          Authorization: `${token}`,
        };
        axios({
          method: "get",
          url: getUrl,
          headers: headersObj,
        })
          .then((res) => {
            updateSession({ languages: res.data });
            resolve(res.data);
          })
          .catch((error) => {
            let code = error?.response?.status ?? 200;
            reject({
              error,
              canRedirect: code === 401 || code === 403,
            });
          });
      } else {
        reject({
          redirect: true,
          message: "No token available till now...",
        });
      }
    }
  });
  return myPromise;
};

const getRegions = (countryCode) => {
  let getUrl = LIVE_REST_API.REGIONS + countryCode;
  return executeRestApi(getUrl, "get");
};

const setLocale = (locale = "en") => {
  updateSession({
    locale,
  });
};

const getLocale = () => {
  let storage = getSession();
  // console.log(storage);
  let locale = storage?.locale ?? "en";

  return locale;
};

const getStoredCountries = (locale) => {
  let storage = getSession();
  // console.log(storage);
  let countries =
    storage?.countries && storage.countries[locale]
      ? storage.countries[locale]
      : {};

  return countries;
};

const getUserInfo = () => {
  let storage = getSession();
  // console.log(storage);
  let userInfo = storage?.userInfo ?? {};
  return userInfo;
};

const validateMembershipNum = (formData) => {
  const myPromise = new Promise((resolve, reject) => {
    let getUrl = LIVE_REST_API.VALIDATE_MEMBERSHIP_NUMBER;
    // console.log(getUrl);
    let headersObj = {};
    if (getToken().length > 0) {
      headersObj = {
        Authorization: getToken(),
      };
    }
    axios
      .post(
        getUrl,
        formData,
        // { validateStatus: false },
        {
          headers: headersObj,
        },
      )
      .then((res) => {
        // console.log(res);
        let token = getToken();
        // if (res?.data?.token?.length > 0 && token.length === 0) {
        if (res?.data?.token?.length > 0) {
          let storageObj = {};
          storageObj[TOKEN] = res.data.token;
          storageObj[GUEST_ACCESS] = true;
          createGuestSession(storageObj);
        }
        resolve(res);
      })
      .catch(function (error) {
        // console.log(error.response.status);
        // console.log(error.response.data.error);
        //if (error.response.status === 401) {
        reject({
          redirect: true,
          error,
        });
        //}
      });
  });

  return myPromise;
};

const saveRecord = (formData) => {
  let url = LIVE_REST_API.SUBMIT_FINAL_OBJ;
  return executeRestApi(url, "post", formData);
};

const getLookupDD = (obj = {}) => {
  let getUrl =
    LIVE_REST_API_PREFIX_LOOKUP + "/" + obj.lookup + "/" + getLocale();
  if (obj?.subLookup) {
    getUrl += "/" + obj.subLookup;
  }
  getUrl += "?programNumber=" + obj.programNumber;
  // console.log(getUrl);
  return executeRestApi(getUrl, "get");
};

const getCountries = () => {
  let currentLocale = getLocale();
  let getUrl = LIVE_REST_API.GET_COUNTRIES + currentLocale;
  let countries = getStoredCountries(currentLocale);
  if (isObjectEmpty(countries)) {
    return executeRestApi(getUrl, "get");
  } else {
    return new Promise((resolve, reject) => {
      resolve(countries);
    });
  }
};

const store = (obj = {}, setPageInfo) => {
  if (obj.newRecord) {
    let currentSession = { ...getSession() };
    currentSession.path = obj.path;

    let genInfo = currentSession.general_information;
    let cerInfo = obj.certificate_information;

    let newRecord = {
      general_information: genInfo,
      certificate_information: cerInfo,
    };

    let requests = currentSession?.requests ?? [];

    requests.push(newRecord);

    delete currentSession.general_information;
    delete currentSession.newRecord;
    currentSession.requests = requests;

    updateSession(currentSession, ["general_information"]);
  } else {
    updateSession(obj);
  }

  let updatedSession = getSession();
  // console.log(updatedSession);
  setPageInfo(updatedSession);
};

const setStoreObj = (aryIndex, callback) => {
  let storingObj = {};
  storingObj.path = Utils.PAGES[aryIndex ?? 0];
  store(storingObj, callback);
};

const getEmailTemplates = () => {
  let url = LIVE_REST_API.EMAIL_TEMPLATES;
  return executeRestApi(url, "get");
};

const addEmailTemplate = (formData) => {
  let url = LIVE_REST_API.EMAIL_TEMPLATES;
  return executeRestApi(url, "post", formData);
};

const editEmailTemplate = (formData, id) => {
  let url = LIVE_REST_API.EMAIL_TEMPLATES + id;
  return executeRestApi(url, "patch", formData);
};

const deleteEmailTemplate = (id) => {
  let deleteUrl = LIVE_REST_API.EMAIL_TEMPLATES + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getLabelTemplates = () => {
  let url = LIVE_REST_API.IDENTIFIER_LABELS;
  return executeRestApi(url, "get");
};

const addLabels = (formData) => {
  let url = LIVE_REST_API.IDENTIFIER_LABELS;
  return executeRestApi(url, "post", formData);
};

const editLabels = (formData, id) => {
  let url = LIVE_REST_API.IDENTIFIER_LABELS + id;
  return executeRestApi(url, "patch", formData);
};

const deleteLabels = (id) => {
  let deleteUrl = LIVE_REST_API.IDENTIFIER_LABELS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getLookupTemplates = () => {
  let url = LIVE_REST_API.LOOKUP_LABELS;
  return executeRestApi(url, "get");
};

const addLookup = (formData) => {
  let url = LIVE_REST_API.LOOKUP_LABELS;
  return executeRestApi(url, "post", formData);
};

const editLookup = (formData, id) => {
  let url = LIVE_REST_API.LOOKUP_LABELS + id;
  return executeRestApi(url, "patch", formData);
};

const deleteLookup = (id) => {
  let deleteUrl = LIVE_REST_API.LOOKUP_LABELS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getCountryTemplates = () => {
  let url = LIVE_REST_API.COUNTRY_LABELS;
  return executeRestApi(url, "get");
};

const addCountry = (formData) => {
  let url = LIVE_REST_API.COUNTRY_LABELS;
  return executeRestApi(url, "post", formData);
};

const editCountry = (formData, id) => {
  let url = LIVE_REST_API.COUNTRY_LABELS + id;
  return executeRestApi(url, "patch", formData);
};

const deleteCountry = (id) => {
  let deleteUrl = LIVE_REST_API.COUNTRY_LABELS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addRegion = (formData) => {
  let url = LIVE_REST_API.REGIONS;
  return executeRestApi(url, "post", formData);
};

const editRegion = (formData, id) => {
  let getUrl = LIVE_REST_API.REGIONS + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteRegion = (id) => {
  let deleteUrl = LIVE_REST_API.REGIONS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getCountryDetails = (countryCode) => {
  let getUrl = LIVE_REST_API.COUNTRY_DETAILS + countryCode;
  return executeRestApi(getUrl, "get");
};

const addCountryDetails = (formData) => {
  let url = LIVE_REST_API.COUNTRY_DETAILS;
  return executeRestApi(url, "post", formData);
};

const editCountryDetails = (formData, id) => {
  let getUrl = LIVE_REST_API.COUNTRY_DETAILS + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteCountryDetails = (id) => {
  let deleteUrl = LIVE_REST_API.COUNTRY_DETAILS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getLegalEntityTemplates = () => {
  let url = LIVE_REST_API.LEGAL_ENTITY;
  return executeRestApi(url, "get");
};

const addLegalEntity = (formData) => {
  let url = LIVE_REST_API.LEGAL_ENTITY;
  formData.isMultipart = true;
  return executeRestApi(url, "post", formData);
};

const editLegalEntity = (formData, id) => {
  let getUrl = LIVE_REST_API.LEGAL_ENTITY + id;
  formData.isMultipart = true;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteLegalEntity = (id) => {
  let deleteUrl = LIVE_REST_API.LEGAL_ENTITY + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addCurrency = (formData) => {
  let url = LIVE_REST_API.CURRENCY_LABELS;
  return executeRestApi(url, "post", formData);
};

const editCurrency = (formData, id) => {
  let getUrl = LIVE_REST_API.CURRENCY_LABELS + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteCurrency = (id) => {
  let deleteUrl = LIVE_REST_API.CURRENCY_LABELS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addCourierServiceProvider = (formData) => {
  let url = LIVE_REST_API.COURIER_SERVICE_PROVIDER;
  return executeRestApi(url, "post", formData);
};

const editCourierServiceProvider = (formData, id) => {
  let getUrl = LIVE_REST_API.COURIER_SERVICE_PROVIDER + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteCourierServiceProvider = (id) => {
  let deleteUrl = LIVE_REST_API.COURIER_SERVICE_PROVIDER + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addMessages = (formData) => {
  let url = LIVE_REST_API.MESSAGE;
  return executeRestApi(url, "post", formData);
};

const editMessages = (formData, id) => {
  let getUrl = LIVE_REST_API.MESSAGE + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteMessages = (id) => {
  let deleteUrl = LIVE_REST_API.MESSAGE + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getMessageDetails = (messageCode) => {
  let getUrl = LIVE_REST_API.MESSAGE_DETAILS + messageCode;
  return executeRestApi(getUrl, "get");
};

const addMessageDetails = (formData) => {
  let url = LIVE_REST_API.MESSAGE_DETAILS;
  return executeRestApi(url, "post", formData);
};

const editMessageDetails = (formData, id) => {
  let getUrl = LIVE_REST_API.MESSAGE_DETAILS + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteMessageDetails = (id) => {
  let deleteUrl = LIVE_REST_API.MESSAGE_DETAILS + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addCertificateTemplates = (formData) => {
  let url = LIVE_REST_API.CERTIFICATE_TEMPLATE;
  return executeRestApi(url, "post", formData);
};

const editCertificateTemplates = (formData, id) => {
  let getUrl = LIVE_REST_API.CERTIFICATE_TEMPLATE + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteCertificateTemplates = (id) => {
  let deleteUrl = LIVE_REST_API.CERTIFICATE_TEMPLATE + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addUser = (formData) => {
  let url = LIVE_REST_API.USER_MANAGEMENT;
  return executeRestApi(url, "post", formData);
};

const editUser = (formData, id) => {
  let getUrl = LIVE_REST_API.USER_MANAGEMENT + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteUser = (id) => {
  let deleteUrl = LIVE_REST_API.USER_MANAGEMENT + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addSellingOfficeDetails = (formData) => {
  let url = LIVE_REST_API.SELLING_OFFICE_MANAGEMENT;
  return executeRestApi(url, "post", formData);
};

const editSellingOfficeDetails = (formData, id) => {
  let getUrl = LIVE_REST_API.SELLING_OFFICE_MANAGEMENT + id;
  return executeRestApi(getUrl, "patch", formData);
};

const syncSellingOfficeData = () => {
  let url = LIVE_REST_API.SELLING_OFFICE_SYN_DATA;
  return executeRestApi(url, "post");
};

const getSellOfficCntLimits = (sellingOfficeId) => {
  let url = LIVE_REST_API.SELLING_OFFICE_COUNTRY_LIMIT + sellingOfficeId;
  return executeRestApi(url, "get");
};

const addSellOfficCntLimits = (formData) => {
  let url = LIVE_REST_API.SELLING_OFFICE_COUNTRY_LIMIT;
  return executeRestApi(url, "post", formData);
};

const editSellOfficCntLimits = (formData, id) => {
  let getUrl = LIVE_REST_API.SELLING_OFFICE_COUNTRY_LIMIT + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteSellOfficCntLimits = (id) => {
  let deleteUrl = LIVE_REST_API.SELLING_OFFICE_COUNTRY_LIMIT + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const getSellOfficMrktCont = () => {
  let url = LIVE_REST_API.SELLING_OFFICE_MARKETING_CONTENT;
  return executeRestApi(url, "get");
};

const getSellOfficMrktCont1 = (sellingOfficeId) => {
  let url = LIVE_REST_API.SELLING_OFFICE_MARKETING_CONTENT + sellingOfficeId;
  return executeRestApi(url, "get");
};

const addSellOfficMrktCont = (formData) => {
  let url = LIVE_REST_API.SELLING_OFFICE_MARKETING_CONTENT;
  return executeRestApi(url, "post", formData);
};

const editSellOfficMrktCont = (formData, id) => {
  let getUrl = LIVE_REST_API.SELLING_OFFICE_MARKETING_CONTENT + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteSellOfficMrktCont = (id) => {
  let deleteUrl = LIVE_REST_API.SELLING_OFFICE_MARKETING_CONTENT + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const addCertifLinkConfig = (formData) => {
  let url = LIVE_REST_API.CERTIFICATE_LINK_CONFIG;
  return executeRestApi(url, "post", formData);
};

const editCertifLinkConfig = (formData, id) => {
  let getUrl = LIVE_REST_API.CERTIFICATE_LINK_CONFIG + id;
  return executeRestApi(getUrl, "patch", formData);
};

const deleteCertifLinkConfig = (id) => {
  let deleteUrl = LIVE_REST_API.CERTIFICATE_LINK_CONFIG + id;
  return executeRestApi(deleteUrl, "delete", {});
};

const fetchData = (category) => {
  let getUrl = LIVE_REST_API[category];
  return executeRestApi(getUrl, "get");
};

const fetchData4Guest = (category) => {
  let getUrl = LIVE_REST_API[category];
  return executeRestApi4Guest(getUrl, "get");
};

const fetchDataByLocale = (category) => {
  let getUrl = LIVE_REST_API[category] + getLocale();
  return executeRestApi(getUrl, "get");
};
const exportToExcel = (formData) => {
  return executeRestApi(LIVE_REST_API.EXPORT_TO_EXCEL, "post", formData);
};

const getProgramDetails = (formData, id, typeOfUser) => {
  let getUrl = LIVE_REST_API.PROGRAM_DETAILS + id + "/" + typeOfUser;
  return executeRestApi(getUrl, "get", formData);
};

const executeRestApi = (getUrl, type = "get", formData = {}) => {
  let isMultipart = formData.isMultipart ?? false;
  const myPromise = new Promise((resolve, reject) => {
    let token = getToken();
    if (token) {
      let headersObj = {
        Authorization: `${token}`,
      };
      isMultipart &&
        (headersObj = { ...headersObj, "Content-Type": "multipart/form-data" });
      delete formData.isMultipart;

      axios({
        method: type,
        url: getUrl,
        data: formData,
        headers: headersObj,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let code = error?.response?.status ?? 200;
          reject({
            error,
            canRedirect: code === 401 || code === 403,
          });
        });
    } else {
      reject({
        redirect: true,
        message: "No token available till now...",
      });
    }
  });

  return myPromise;
};

const executeRestApi4Guest = (getUrl, type = "get", formData = {}) => {
  let isMultipart = formData.isMultipart ?? false;
  const myPromise = new Promise((resolve, reject) => {
    let headersObj = {};
    isMultipart &&
      (headersObj = { ...headersObj, "Content-Type": "multipart/form-data" });
    delete formData.isMultipart;

    axios({
      method: type,
      url: getUrl,
      data: formData,
      headers: headersObj,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject({
          error,
        });
      });
  });

  return myPromise;
};

const storeMessages = (obj = {}, callback) => {
  const transformData = (inputArray) => {
    const transformedObject = {};

    inputArray?.forEach((item) => {
      const { id, messageCode, language, message } = item;

      if (!transformedObject[messageCode]) {
        transformedObject[messageCode] = {};
      }

      transformedObject[messageCode][language] = message;
      // {
      //   id,
      //   message
      // };
    });
    return transformedObject;
  };

  let messageObj = { messages: transformData(obj) };
  store(messageObj, callback);
};

const fetchMessages = () => {
  return fetchData4Guest("MESSAGE_CODES");
};

const filterTableData = ({
  event,
  templates,
  setTemplates,
  filteredTemplates,
  setFilteredTemplates,
}) => {
  let e = event;
  let searchText = e.currentTarget.value;

  let updatedTemplates = [...templates];
  searchText = searchText.trim().toLowerCase();
  if (searchText.length > 1)
    updatedTemplates = updatedTemplates.filter((template) => {
      let hasRecord = false;
      Object.keys(template).forEach((key) => {
        let val = (template[key] + "").toLowerCase();
        val.indexOf(searchText) !== -1 && (hasRecord = true);
      });
      return hasRecord;
    });
  let sortColKey = e.currentTarget.getAttribute("sort-key");

  if (sortColKey.length) {
    let sortType = e.currentTarget.getAttribute("sort-type") ?? "asc";
    updatedTemplates = getSortedTemplates(
      sortType,
      sortColKey,
      updatedTemplates,
    );
  }
  setFilteredTemplates(updatedTemplates);
};

const sortTable = ({
  event,
  templates,
  setTemplates,
  filteredTemplates,
  setFilteredTemplates,
  searchText = "",
}) => {
  let e = event;
  let sortedColumns = document.getElementsByClassName("certif-col-sorted");
  for (let i = 0; i < sortedColumns.length; i++) {
    let sortedCol = sortedColumns[i];
    sortedCol.classList.remove("certif-col-sorted");
    sortedCol.classList.remove("certif-sort-desc");
    sortedCol.classList.remove("certif-sort-asc");
  }

  let colKey = e.currentTarget.getAttribute("sort-col-id");
  let sortType = e.currentTarget.getAttribute("sort-col-type") ?? "asc";
  let colId = colKey + "_th";
  let colSortTypeClass = "certif-sort-" + sortType;
  let newSortColEle = document.getElementById(colId);
  newSortColEle.classList.add("certif-col-sorted");
  newSortColEle.classList.add(colSortTypeClass);

  let updatedTemplates = [...filteredTemplates];
  searchText = searchText.trim().toLowerCase();

  updatedTemplates = getSortedTemplates(sortType, colKey, updatedTemplates);

  setFilteredTemplates(updatedTemplates);

  let interactiveFilterEleId = e.currentTarget.getAttribute(
    "sort-col-interactive-id",
  );
  if (interactiveFilterEleId?.length) {
    let interactiveFilterEle = document.getElementById(interactiveFilterEleId);

    interactiveFilterEle?.setAttribute("sort-type", sortType);
    interactiveFilterEle?.setAttribute("sort-key", colKey);
  }
};

const getSortedTemplates = (sortType = "asc", colKey, templates) => {
  // console.log(colKey);
  let updatedTemplates = [...templates];
  if (sortType === "asc") {
    updatedTemplates = updatedTemplates.sort((a, b) =>
      a[colKey] > b[colKey] ? 1 : -1,
    );
  } else {
    updatedTemplates = updatedTemplates.sort((a, b) =>
      b[colKey] > a[colKey] ? 1 : -1,
    );
  }
  return updatedTemplates;
};

const handleDefaultError = ({
  error,
  setLoadingState,
  setCanRedirectToLogin,
  setCanRedirectToError,
  setPageInfo,
  errorCode,
}) => {
  // console.log(error.message);
  let errCode = errorCode ?? error?.response?.status ?? 401;
  setLoadingState &&
    setLoadingState({
      applyMask: false,
    });

  let session = getSession();
  let tempObj = { ...session, ...{ path: PAGES[0] } };
  store(tempObj, setPageInfo);

  errCode === 401 && setCanRedirectToLogin && setCanRedirectToLogin(true);
  errCode === 403 && setCanRedirectToError && setCanRedirectToError(true);
};

const downloadFile = ({ src, setLoadingState }) => {
  setLoadingState &&
    setLoadingState({
      applyMask: true,
    });
  let fileName = src.substr(src.lastIndexOf("/") + 1, src.length);
  // console.log(fileName);

  const success = (response) => {
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    setLoadingState &&
      setLoadingState({
        applyMask: false,
      });
  };

  const fail = (error) => {
    console.log(error);
    setLoadingState &&
      setLoadingState({
        applyMask: false,
      });
  };

  const getFileToDownload = (apiUrl) => {
    return axios.get(apiUrl, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getFileToDownload(src).then(success, fail);
};

const Utils = {
  PAGES,
  LIVE_REST_API,
  LIVE_REST_API_EXT_PREFIX,
  APP_URLS,
  NAV_ITEMS_KEYS,
  ADMIN_NAV_ITEMS_KEYS,
  USER_NAV_ITEMS_KEYS,
  NAV_ITEMS_VALUES,
  EMPTY_ERROR_FIELDS,
  NON_EMPTY_ERROR_FIELDS,
  ERROR_TYPE_TO_FIELDS_MAPPING,
  MAX_RECORDS_ALLOWED,
  MEDICAL_EXPENSES_LIMIT,
  FURTHER_HELP_OR_SUPPORT_LINK,
  CURRENCIES,
  ADMIN_SECTIONS_ERROR_TEMPLATE,
  PLACEHOLDER_4_SEARCH_IN_ADMIN_SCREENS,
  USER_INTERACTION_MESSAGES_4_ADMIN_SCREENS,
  createSession,
  getSession,
  deleteSession,
  resetSession,
  isObjectEmpty,
  getLanguages,
  getStoredLanguages,
  getRegions,
  getLocale,
  setLocale,
  validateMembershipNum,
  store,
  getLookupDD,
  getCountries,
  saveRecord,
  createOKTASession,
  getToken,
  setStoreObj,
  getEmailTemplates,
  addEmailTemplate,
  editEmailTemplate,
  deleteEmailTemplate,
  getLabelTemplates,
  addLabels,
  editLabels,
  deleteLabels,
  getLookupTemplates,
  addLookup,
  editLookup,
  deleteLookup,
  getCountryTemplates,
  addCountry,
  editCountry,
  deleteCountry,
  addRegion,
  editRegion,
  deleteRegion,
  getCountryDetails,
  addCountryDetails,
  editCountryDetails,
  deleteCountryDetails,
  getLegalEntityTemplates,
  addLegalEntity,
  editLegalEntity,
  deleteLegalEntity,
  addCurrency,
  editCurrency,
  deleteCurrency,
  addCourierServiceProvider,
  editCourierServiceProvider,
  deleteCourierServiceProvider,
  addMessages,
  editMessages,
  deleteMessages,
  getMessageDetails,
  addMessageDetails,
  editMessageDetails,
  deleteMessageDetails,
  addCertificateTemplates,
  editCertificateTemplates,
  deleteCertificateTemplates,
  addUser,
  editUser,
  deleteUser,
  addSellingOfficeDetails,
  editSellingOfficeDetails,
  syncSellingOfficeData,
  getSellOfficCntLimits,
  addSellOfficCntLimits,
  editSellOfficCntLimits,
  deleteSellOfficCntLimits,
  getSellOfficMrktCont,
  getSellOfficMrktCont1,
  addSellOfficMrktCont,
  editSellOfficMrktCont,
  deleteSellOfficMrktCont,
  addCertifLinkConfig,
  editCertifLinkConfig,
  deleteCertifLinkConfig,
  fetchData,
  fetchData4Guest,
  exportToExcel,
  getProgramDetails,
  fetchDataByLocale,
  isGuestAccess,
  isSuperAdminAccess,
  storeMessages,
  fetchMessages,
  getUserInfo,
  filterTableData,
  handleDefaultError,
  sortTable,
  downloadFile,
};

export default Utils;
