import React, { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";

import { useOktaAuth } from "@okta/okta-react";

import { Container } from "react-bootstrap";
import Utils from "../../controls/Utils";
import AppContextComponent from "../../controls/AppContext";

const LoginForm = () => {
  let { setPageInfo } = useContext(AppContextComponent);

  const navigate = useNavigate();
  const location = useLocation();
  const { authState, oktaAuth, authClient } = useOktaAuth();

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  useEffect(() => {
    Utils.setStoreObj(0, setPageInfo);
    // console.log(authState);
    // console.log(oktaAuth);
    // console.log(authClient);
    // console.log(code);
    // console.log(state);
  }, []);

  useEffect(() => {
    // if (authState?.isAuthenticated) {
    //   console.log(" ========================= ");
    // } else {
    //   console.log(" ------------------------ ");
    // }

    let sessionObj = JSON.parse(
        sessionStorage.getItem("okta-transaction-storage"),
      ),
      issuer = sessionObj.issuer,
      clientId = sessionObj.clientId,
      codeChallenge = sessionObj.codeChallenge,
      codeVerifier = sessionObj.codeVerifier,
      nonce = sessionObj.nonce,
      redirectUri = sessionObj.redirectUri,
      tokenUrl = sessionObj.urls.tokenUrl;

    axios({
      method: "post",
      url: tokenUrl,
      data: {
        grant_type: "authorization_code",
        code,
        client_id: clientId,
        redirect_uri: redirectUri,
        state,
        codeVerifier,
        code_verifier: codeVerifier,
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let session = { ...res.data };
      // session.reload = true;
      // debugger;
      Utils.createOKTASession(session);
      // navigate(Utils.APP_URLS.LANDING_PAGE);
      window.location = Utils.APP_URLS.LANDING_PAGE;
    }).catch((error) => {
      let commonFn = (data) => {
        Utils.deleteSession();
        // TODO --> set some error suitable error message in local storage
        // Navigate to internal okta login
        // get the error message from local storage and show it in the login page and delete the session
        // naviage to same login page by appending invalid user
        // invalid user. 
      };
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server responded with status code:", error.response.status);
        console.error("Response data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
      try {
        // Attempt to log the user out
        oktaAuth.signOut().then(commonFn, commonFn);
      } catch (signOutError) {
        console.error('Error during Okta sign-out:', signOutError);
      }
      // Optionally navigate to an error page or display an error message
      window.location = Utils.APP_URLS.ERROR_PAGE;
      // You might want to handle different error scenarios here, e.g., display an error message
    });
  }, [authState, oktaAuth]);

  return (
    <Container className="vh-100 vw-100 p-0 m-0 mw-100 mh-100 d-none">
      <div>Loading...</div>
      <div></div>
    </Container>
  );
};

export default LoginForm;
