import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import i18n from "./../../../i18n/config";

import Utils from "../../../controls/Utils";
import enFlag from "./../../../assets/img/flag-en.png";
import frFlag from "./../../../assets/img/flag-fr.png";

import AppContextComponent from "../../../controls/AppContext";

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  let { pageInfo, setPageInfo, readyToRender } =
    useContext(AppContextComponent);
  const { t } = useTranslation();
  const flags = {
    en: enFlag,
    fr: frFlag,
  };

  const langDropdownDisplayNames = {
    en: "English",
    fr: "Français"
  }

  let [navigationItemKeys, setNavigationItemKeys] = useState([]);
  let [navLinkValues, setNavLinkValues] = useState({});

  let [showAdminNavItems, setShowAdminNavItems] = useState(false);
  let [langDDClass, setLangDDClass] = useState("");
  let [defLang, setDefLang] = useState(Utils.getLocale());
  let [langData, setLangData] = useState([]);
  const ACTIVE_CLASSNAME = "certif-active-nav-item";

  const handleChange = (e) => {
    let newLocale = e.value;
    Utils.setLocale(newLocale);
    i18n.changeLanguage(newLocale);
    fetchLangs(newLocale);
  };
  const fetchLangs = (loc) => {
    const success = (res) => {
      let langs = {};

      res.data.forEach((rec) => {
        // langs[rec.value] = rec.displayName;
        langs[rec.value] = langDropdownDisplayNames[rec.value];
      });
      let langData1 = [];
      let defLang1 = {};
      let currLang = Utils.getLocale();
      Object.keys(langs).forEach((key) => {
        let lang = {};
        lang.value = key;
        lang.label = langs[key];
        lang.image = flags[key];

        langData1.push(lang);
        if (key === currLang) {
          defLang1 = lang;
        }
      });
      // console.log(langData);
      setLangData(langData1);

      setDefLang(defLang1);
    };
    const error = (res) => {
      console.log(res);
    };
    Utils.getLanguages(loc).then(success, error);
  };

  useEffect(() => {
    let pathName = (location.pathname ?? "").replace("/", "");
    if (pathName.replace("/", "") === "") {
      let session = Utils.getSession();
      pathName = session?.path ?? "";
    }
    let index = Utils.PAGES.indexOf(pathName);
    Utils.setStoreObj(index, setPageInfo);
    fetchLangs(pageInfo.locale ?? 'en');

    let tempNavItemKeys = [...Utils.NAV_ITEMS_KEYS];
    if (Utils.getToken() && !Utils.isGuestAccess()) {
      setShowAdminNavItems(true);
      let session = Utils.getSession();
      let extraNavItemKeys =
        session?.userInfo?.certifRoles?.indexOf("ROLE_USER") === -1
          ? Utils.ADMIN_NAV_ITEMS_KEYS
          : Utils.USER_NAV_ITEMS_KEYS;
      tempNavItemKeys = [...tempNavItemKeys, ...extraNavItemKeys];
    }

    setNavLinkValues(Utils.NAV_ITEMS_VALUES);
    setNavigationItemKeys(tempNavItemKeys);
  }, [readyToRender]);

  useEffect(() => {
    let path = pageInfo?.path ?? "";
    let currPath = location?.pathname;
    currPath = currPath === "" || currPath === "/" ? Utils.PAGES[0] : currPath;
    if (
      (path.length && path === Utils.PAGES[0]) ||
      (path.length === 0 && currPath === Utils.PAGES[0])
    ) {
      setLangDDClass("");
    } else {
      setLangDDClass("d-none");
    }
  }, [showAdminNavItems, pageInfo.path]);

  const isAtRootUrl = location?.pathname === Utils.APP_URLS.LANDING_PAGE;

  const handleNavItemClick = (e) => {
    let key = e.currentTarget.id;
    navLinkValues[key].handler(setPageInfo);
  };

  return (
    <div className="certif-nav-wrapper">
      <div className="container d-flex flex-row align-items-center">
        {/* <div className="certif-nav-items-wrapper certif-nav-item- certif-nav-item-login certif-nav-item-home">
          <div className="certif-nav-items">
            <div className="certif-nav-item">{t("home")} </div>
            <div className="certif-arrow-up certif-set-buttom"></div>
          </div>
        </div> */}

        {navigationItemKeys.map((navKey, index, src) => (
          <div
            className={`certif-nav-items-wrapper certif-nav-item-${navKey}`}
            key={index}
          >
            <NavLink
              className={({ isActive }) => {
                if (isAtRootUrl) {
                  isActive = index === 0;
                }

                let activeEle = document.querySelector(
                  ".certif-nav-item-" + navKey,
                );
                if (activeEle) {
                  if (isActive) {
                    activeEle.classList.add(ACTIVE_CLASSNAME);
                  } else {
                    activeEle.classList.remove(ACTIVE_CLASSNAME);
                  }
                }
                return "";
              }}
              to={navLinkValues[navKey].url}
              id={navKey}
              onClick={handleNavItemClick}
            >
              {({ isActive }) => (
                <>
                  <div className="certif-nav-items">
                    <div className="certif-nav-item">
                      {t(navLinkValues[navKey].title)}
                    </div>
                    <div className="certif-arrow-up certif-set-buttom"></div>
                  </div>
                </>
              )}
            </NavLink>
          </div>
        ))}

        <div
          className={`certif-nav-lang-selection-wrapper ms-auto ${langDDClass}`}
        >
          <div className="certif-nav-lang-selection">
            <Select
              placeholder="Select Option"
              value={defLang} // set selected value
              options={langData} // set list of the data
              onChange={handleChange} // assign onChange function
              className="certif-dropdown-wrapper"
              formatOptionLabel={(data) => (
                <div className="country-option">
                  <img src={data.image} alt={data.label} />
                  <span className="certif-lang-dd-value">{data.label}</span>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
