import React, { useEffect, useContext, useState, useRef } from "react";
import Ribbon from "./Ribbon";
import AppContextComponent from "../../../controls/AppContext";
import Utils from "../../../controls/Utils";

import { useTranslation, Trans } from "react-i18next";
import { Modal } from "react-bootstrap";

import QuestionImg from "./../../../assets/img/icon-question.png";
import RemoveImg from "./../../../assets/img/icon-del.png";
import TickImg from "./../../../assets/img/yes.png";
import ModifyImg from "./../../../assets/img/icon-modify.png";
import DeleteImg from "./../../../assets/img/bin.png";
import AddImg from "./../../../assets/img/icon-plus.png";
import Error from "./Error";

import TermsAndConditionsPDFFileEn from "./../../../assets/docs/GeneralTermsofUse_COA_en.pdf";
import TermsAndConditionsPDFFileFr from "./../../../assets/docs/GeneralTermsofUse_COA_fr.pdf";

function Confirmation({ props }) {
  const MAX_RECORDS_ALLOWED = Utils.MAX_RECORDS_ALLOWED;
  let pdfFiles = {
    en: TermsAndConditionsPDFFileEn,
    fr: TermsAndConditionsPDFFileFr,
  };
  let active = Utils.PAGES[3];

  let { t } = useTranslation();

  let [emailAddresses, setEmailAddresses] = useState("");
  let [extEmailAddresses, setExtEmailAddresses] = useState([]);
  let [emailError, setEmailError] = useState("");
  let [errorFields, setErrorFields] = useState([]);
  let [isInternalUser, setIsInternalUser] = useState(!Utils.isGuestAccess());

  let continueBtnRef = useRef(null);
  let cbEleRef = useRef(null);

  const [checked, setChecked] = useState(false);

  let {
    pageInfo,
    setPageInfo,
    setLoadingState,
    setCanRedirectToLogin,
    setCanRedirectToError,
  } = useContext(AppContextComponent);

  let [recordsCount, setRecordsCount] = useState(0);
  let [records, setRecords] = useState(pageInfo.requests ?? []);

  let [modalCanOpen, setModalCanOpen] = useState(false);
  let [modalTitle, setModalTitle] = useState("");

  const deleteTravelEntry = (e) => {};

  const getLangugeLocale = {
    English: {
      en: "English",
      fr: "French",
    },
    French: {
      en: "Anglais",
      fr: "Français",
    },
  };

  const getLocaleAndMap2CertifLang = (certifLanguage) => {
    // first get the locale language
    let locLang = Utils.getLocale();
    locLang = locLang === "en" ? "English" : "French";

    return getLangugeLocale[locLang][certifLanguage];
  };

  const setEmailIds = (requests) => {
    let emails = [];
    requests?.forEach((record) => {
      record.certificate_information.travelDetailsArray.forEach((travel) => {
        emails.push(travel.empEmail);
      });
    });
    if (emails.length > 0) {
      let totoalEmails = Array.from(new Set([...emails]));
      setEmailAddresses(totoalEmails.join("; "));
    } else {
      setEmailAddresses("");
      setChecked(false);
      cbEleRef.current.setAttribute("disabled", "");
    }
  };

  useEffect(() => {
    setEmailIds(pageInfo?.requests);

    let externalEmailAddresses = pageInfo?.externalEmails ?? [];
    let totoalExtEmails = [...externalEmailAddresses];
    totoalExtEmails = totoalExtEmails.filter((email) => {
      return email.length;
    });
    setExtEmailAddresses(totoalExtEmails);
  }, [pageInfo?.externalEmails]);

  const addExternalEmailAddress = (e) => {
    e.preventDefault();

    let emailInputText = document.getElementById("externalEmailAddressInput");
    let inputEmail = emailInputText.value.trim();

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);

    if (isValidEmail) {
      setEmailError("");
    } else {
      setEmailError(t("emailError"));
      return;
    }

    let externalEmailAddresses = [...(pageInfo?.externalEmails ?? [])];
    externalEmailAddresses.push(emailInputText.value);
    Utils.store(
      {
        externalEmails: externalEmailAddresses,
      },
      setPageInfo,
    );
    emailInputText.value = "";
  };

  const addNewRequest = (e) => {
    if (recordsCount >= MAX_RECORDS_ALLOWED) {
      let tempErrorFields = {};
      let fieldId = "maxCertificates";
      if (!tempErrorFields[fieldId]) {
        tempErrorFields[fieldId] = {
          fieldId,
          errorTypes: [], // Initialize an array for error types
        };
      }
      tempErrorFields[fieldId].errorTypes.push("invalidFormat");
      let errorFields = Object.values(tempErrorFields);
      setErrorFields(errorFields);
    } else {
      setErrorFields([]);
      Utils.store(
        {
          path: Utils.PAGES[1],
        },
        setPageInfo,
      );
    }
  };

  const deleteRecord = (e, index1, index) => {
    let tempPageInfo = { ...pageInfo };
    // debugger;
    // if(window.ravi) {
    //   if(window.kumar) {
    //     let obj = localStorage.getItem("ravi");
    //     Utils.store(JSON.parse(obj), setPageInfo);
    //     debugger;
    //   } else {
    //     localStorage.setItem("ravi", JSON.stringify(tempPageInfo));
    //   }
    // }
    let targetTravelDetailsArray =
      tempPageInfo.requests[index1.index1].certificate_information
        .travelDetailsArray;
    targetTravelDetailsArray.splice(index.index, 1);
    Utils.store(tempPageInfo, setPageInfo);
    // console.log(tempPageInfo.requests);
    setRecords(tempPageInfo.requests);
    setEmailIds(tempPageInfo.requests);
    setErrorFields([]);
    getActiveRecordsCount();
  };

  const editRecord = (e, index1, index) => {
    let tempPageInfo = { ...pageInfo };
    tempPageInfo.edit = {
      index1: index1.index1,
      index: index.index,
    };
    tempPageInfo.path = Utils.PAGES[2];
    Utils.store(tempPageInfo, setPageInfo);
  };

  useEffect(() => {}, [pageInfo]);

  useEffect(() => {
    isInternalUser && setChecked(true);
    if (!isInternalUser) {
      setTimeout(() => {
        let continueBtnEle = document.getElementById("continueBtn");
        continueBtnEle.setAttribute("disabled", "");
      }, 10);
    }
  }, []);

  const COURIER_FIELDS = {
    courierType: "byMail",
    courierServiceProvider: "courierServiceProvider",
    courierAccountNumber: "courierAccountNumber",
    noOfOrigCertificates: "noOfOrigCertificates",
    sdCompanyOrOrganisation: "companyName",
    sdTitle: "title",
    sdFirstName: "firstName",
    sdLastName: "lastName",
    sdAddress: "address",
    sdCity: "city",
    sdZipCode: "zipCode",
    sdPhone: "phoneNumber",
  };

  let session = Utils.getSession();
  let loc = Utils.getLocale();
  let ctrys = session.countries[loc];
  let [countries, setCountries] = useState(ctrys.data);

  const getCountryLabel = (ctry) => {
    let label = ctry;
    countries.forEach((country) => {
      ctry === country.countryCode && (label = country.countryName);
    });
    // console.log(label);
    return label;
  };

  const continueBtnHandler = (e) => {
    e.preventDefault();
    // console.log(" inside continueBtnHandler... ");

    let sessionObj = { ...Utils.getSession() };
    // console.log(sessionObj);

    let finalObj = {};
    finalObj.programNumber = sessionObj.programNumber;
    finalObj.locale = sessionObj.locale;
    finalObj.additionalEmails = sessionObj.externalEmails;
    finalObj.requests = [];

    sessionObj.requests.forEach((rec) => {
      let tempRec = {};
      tempRec.selectionDetails = rec.general_information;
      tempRec.travelDetails = rec.certificate_information.travelDetailsArray;

      let internalArray = rec.certificate_information.travelDetailsArray;

      internalArray.forEach((childRec) => {
        childRec["courierDetails"] = {};
        Object.keys(COURIER_FIELDS).forEach((fieldId) => {
          let tempValue = childRec[fieldId];
          delete childRec[fieldId];

          if (fieldId === "courierType") {
            childRec["courierDetails"]["byMail"] = tempValue === "mail";
          } else {
            childRec["courierDetails"][COURIER_FIELDS[fieldId]] = tempValue;
          }
        });

        finalObj.requests.push(childRec);
      });
    });

    console.log(finalObj);

    const saveSuccess = (res) => {
      console.log(res);
      props.setMarktContApiRes(res.data);
      let tempObj = { ...pageInfo };
      tempObj.path = Utils.PAGES[4];
      Utils.store(tempObj, setPageInfo);

      setLoadingState({
        applyMask: false,
      });
    };

    const saveFail = ({ error }) => {
      Utils.handleDefaultError({
        error,
        setLoadingState,
        setCanRedirectToLogin,
        setCanRedirectToError,
        setPageInfo,
      });
    };

    setLoadingState({
      applyMask: true,
    });

    Utils.saveRecord(finalObj).then(saveSuccess, saveFail);
  };

  const checkboxHandler = (e) => {
    setChecked(!checked);
  };

  useEffect(() => {
    let continueBtnEle = continueBtnRef.current;
    let method = checked ? "removeAttribute" : "setAttribute";
    continueBtnEle[method]("disabled", "");
  }, [checked]);

  useEffect(() => {
    let count = 0;
    records.forEach((record) => {
      count += record.certificate_information.travelDetailsArray.length;
    });
    setRecordsCount(count);
  }, [records]);

  const getActiveRecordsCount = () => {
    let count = 0;
    records.forEach((record) => {
      count += record.certificate_information.travelDetailsArray.length;
    });
    setRecordsCount(count);
  };

  const removeExtEmail = (e) => {
    let removeBtnEle = e.currentTarget;
    let extEmailToBeRemove = removeBtnEle.getAttribute("data-value") ?? "";
    if (extEmailToBeRemove.length) {
      let externalEmailAddresses = [...(pageInfo?.externalEmails ?? [])];
      let totoalExtEmails = [...externalEmailAddresses];
      totoalExtEmails = totoalExtEmails.filter((email) => {
        return email !== "" && email !== extEmailToBeRemove;
      });
      Utils.store(
        {
          externalEmails: totoalExtEmails,
        },
        setPageInfo,
      );
    }
  };

  let [pdfSrcFile, setPdfSrcFile] = useState(pdfFiles[pageInfo.locale ?? 'en']);
  const pdfClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModalCanOpen(true);
  };

  const handleClose = (e) => {
    setModalCanOpen(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        className="certif-large-modal"
        show={modalCanOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed
            className="pdfobject"
            type="application/pdf"
            title="Embedded PDF"
            // src="pdf/sample-3pp.pdf"
            src={pdfSrcFile}
            style={{ overflow: "auto", width: "100%", height: "100%" }}
          />
        </Modal.Body>
      </Modal>

      <div>
        <div className="certif-intro-title">{t("confInfo")}</div>
        <p>{t("confInfoDesc")}</p>

        <Ribbon props={{ active }} />
        <Error props={{ errorMessageArray: errorFields }} />

        <div className="certif-confimation-page">
          <div className="certif-table-wrapper">
            {t("confInfoTableHeading1")}
          </div>
          <div className="certif-table-body table-responsive">
            <table className="table table-borderless certif-inner-table m-0">
              <thead className="certif-table-header">
                <tr>
                  <th>{t("companyOrOrganisation")}</th>
                  <th>{t("lastName")} </th>
                  <th>{t("firstName")} </th>
                  <th>{t("destinationCountry")} </th>
                  <th>{t("certificateLanguage")} </th>
                  <th>{t("travelDateFrom")} </th>
                  <th>{t("travelDateTo")} </th>
                  <th>{t("originalRequested")} </th>
                  <th>{t("modify")} </th>
                  <th>{t("delete")} </th>
                </tr>
              </thead>
              {recordsCount > 0 && (
                <tbody>
                  {records?.map((record, index1) => (
                    <React.Fragment key={index1}>
                      {record.certificate_information.travelDetailsArray?.map(
                        (subRecord, index) => (
                          <tr key={index}>
                            <td>
                              {subRecord.subsidiaryCheck &&
                              subRecord.subsidiary.length > 0
                                ? subRecord.subsidiary
                                : subRecord.company}
                            </td>
                            <td>{subRecord.empLastName}</td>
                            <td>{subRecord.empFirstName}</td>
                            <td>
                              {getCountryLabel(subRecord.destinationCountry)}
                            </td>
                            <td>
                              {getLocaleAndMap2CertifLang(subRecord.language)}
                            </td>
                            <td>{subRecord.startDate}</td>
                            <td>{subRecord.endDate}</td>
                            <td>
                              {subRecord.originalRequested === true && (
                                <img
                                  src={TickImg}
                                  alt="Requested on"
                                  data-index={`${index1}:${index}`}
                                />
                              )}
                              {subRecord.originalRequested === false && (
                                <img
                                  src={RemoveImg}
                                  alt="Requested off"
                                  data-index={`${index1}:${index}`}
                                />
                              )}
                            </td>
                            <td>
                              <img
                                src={ModifyImg}
                                role="button"
                                // onClick={deleteRecord}
                                onClick={(event) =>
                                  editRecord(event, { index1 }, { index })
                                }
                                alt="modfy"
                              />
                            </td>
                            <td>
                              {/* <img src={DeleteImg} role="button" onClick={ deleteRecord({index1}, {index})} alt="delete" /> */}
                              <img
                                src={DeleteImg}
                                role="button"
                                // onClick={deleteRecord}
                                onClick={(event) =>
                                  deleteRecord(event, { index1 }, { index })
                                }
                                alt="delete"
                              />
                            </td>
                          </tr>
                        ),
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          <div>
            <p>
              <b>{t("confEmail")} : </b>
              {emailAddresses}
            </p>
          </div>

          <div>
            <p className="certif-flex-check-label-wrapper certif-flex-external-check-label-wrapper">
              <span>
                <b>{t("confExtEmail")} : </b>
              </span>
              <span className="certif-external-mail-input-wrapper">
                <input
                  className="certif-external-mail-input"
                  type="text"
                  id={"externalEmailAddressInput"}
                />
                {emailError && (
                  <span style={{ color: "red" }}>{emailError}</span>
                )}
                <span className="certif-external-mail-list">
                  {extEmailAddresses.map((extEmail, index) => (
                    <span
                      className="certif-external-mail-list-item"
                      key={index}
                    >
                      <span className="certif-external-mail-list-item-value">
                        {extEmail}
                      </span>
                      <span
                        className="certif-external-mail-list-item-remove"
                        data-value={extEmail}
                        role="button"
                        onClick={removeExtEmail}
                      >
                        x
                      </span>
                    </span>
                  ))}
                </span>
              </span>
              <img
                className="certif-flex-plus-input"
                src={AddImg}
                alt="add"
                onClick={addExternalEmailAddress}
              />
            </p>
          </div>

          <div>
            <p
              className={`certif-flex-check-label-wrapper
            ${
              isInternalUser
                ? "certif-confirmation-page-acknowledge-prevent-user-pointer"
                : ""
            }`}
            >
              <input
                type="checkbox"
                ref={cbEleRef}
                id="acknowledgeCB"
                checked={checked}
                onChange={checkboxHandler}
              />
              <label>
                <Trans
                  i18nKey="confAcknowledgement"
                  components={{
                    linkTag1: (
                      <a
                        href=""
                        className="certif-pdf-link"
                        onClick={pdfClickHandler}
                      />
                    ),
                  }}
                />
              </label>
            </p>
          </div>

          <table className="table table-borderless m-0">
            <tfoot className="certif-table-footer">
              <tr>
                <th colSpan="3">
                  <button
                    className="btn btn-secondery btn-sm certif-align-btn-left"
                    onClick={addNewRequest}
                  >
                    {t("addRequest")}
                  </button>
                  {/* {recordsCount < MAX_RECORDS_ALLOWED && (
                    <button
                      className="btn btn-secondery btn-sm certif-align-btn-left"
                      onClick={addNewRequest}
                    >
                      {t("addRequest")}
                    </button>
                  )} */}
                  {/* {recordsCount >= MAX_RECORDS_ALLOWED && (
                    <>Maximum {MAX_RECORDS_ALLOWED} record allowed</>
                  )} */}
                  <button
                    ref={continueBtnRef}
                    id={"continueBtn"}
                    onClick={continueBtnHandler}
                    className="btn btn-secondery btn-sm"
                  >
                    {t("continue")}
                  </button>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}

export default Confirmation;
